import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ApiService } from '../shared/services/api.service';

@Injectable()
export class ProductService extends ApiService {

    constructor(public http: HttpClient) {
        super(http, 'products');
    }

    remove(categoryId: number, productId: number) {
        return this.http.post(environment.api + 'products/' + productId + '/remove', { categoryId: categoryId });
    }

    savePositions(ids: number[]) {
        return this.http.post(environment.api + 'products/positions', { ids: ids });
    }
}
