<div id="home" *ngIf="!isAuthenticated">

    <div id="header">
        <ic-icon id="home-icon" [icon]="bxsHomeHeart"></ic-icon>
        My Placy
    </div>

    <div class="grid grid-nogutter padding30">
        <div class="col-12 md:col-8" *ngIf="!redirect">
            <div class="center">
            </div>
        </div>

        <div class="col-12 md:col-4">

            <div class="forms">
                <p-card>
                    <div class="title">
                        <ng-container *ngIf="!showResetPassword">Connexion</ng-container>
                        <ng-container *ngIf="showResetPassword">Mot de passe oublié</ng-container>
                    </div>
                    <form (ngSubmit)="login()" #pageForm ngForm>
                        <div class="p-field">
                            <label for="email">Adresse email</label>
                            <input type="text" pInputText [(ngModel)]="user.email" id="email" name="email" required
                                #email ngModel email="true">
                        </div>
                        <ng-container *ngIf="!showResetPassword">
                            <div class="p-field">
                                <div id="password-line">
                                    <label for="password">Mot de passe</label>
                                    <span *ngIf="false" (click)="showResetPassword=true">Mot de passe oublié ?</span>
                                </div>
                                <input type="password" pInputText [(ngModel)]="user.password" id="password"
                                    name="password" required #password ngModel>
                            </div>
                            <p-button label="Se connecter" type="submit"
                                [disabled]="!user.email || !user.password || submitting">
                                <fa-icon *ngIf="submitting" [icon]="faSpinner" [spin]="true"></fa-icon>
                            </p-button>
                        </ng-container>
                        <ng-container *ngIf="showResetPassword">
                            <p-button label="Réinitialiser le mot de passe" type="button" (click)="resetPassword()"
                                [disabled]="!user.email || submitting">
                                <fa-icon *ngIf="submitting" [icon]="faSpinner" [spin]="true"></fa-icon>
                            </p-button>
                        </ng-container>
                        <br><br>
                        <div>
                            <p-message *ngIf="loginError" severity="error" [text]="loginErrorMessage"></p-message>
                        </div>
                    </form>
                </p-card>
            </div>

            <div class="forms" *ngIf="!redirect && !showResetPassword">
                <p-card>
                    <p-button label="Créer un compte" (click)="register()"></p-button>
                </p-card>
            </div>

        </div>

    </div>

</div>