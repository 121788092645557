import { Establishment } from "./establishment";
import { Schedule } from "./schedule";

export class User {
    id: number;
    email: string;
    firstname: string;
    lastname: string;
    city: string;
    password: string;
    password1: string;
    password2: string;
    lat: number;
    lng: number;
    schedules?: Schedule[];
    establishments: Establishment[];
    roles: string[];
}
