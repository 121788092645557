import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

export abstract class ApiService {

    constructor(public http: HttpClient, protected path: string) {
    }

    save(data: any) {
        if (data.id) {
            return this.http.put(environment.api + this.path + '/' + data.id, data);
        }
        return this.http.post(environment.api + this.path, data);
    }

    delete(id: number) {
        return this.http.delete(environment.api + this.path + '/' + id);
    }
}
