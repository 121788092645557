import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './modules/home/home.component';
import { UserRegisterComponent } from './modules/user/user-register/user-register.component';

const routes: Routes = [
  { path: 'light/:id', loadChildren: () => import('./modules/back-office-light/back-office-light.module').then(m => m.BackOfficeLightModule) },
  { path: '', component: HomeComponent },
  { path: 'home', component: HomeComponent },
  { path: 'register', component: UserRegisterComponent },
  { path: 'r/:id', loadChildren: () => import('./modules/back-office/back-office.module').then(m => m.BackOfficeModule) },
  { path: 'tdb', loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule) },
  { path: 'admin', loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy', anchorScrolling: 'enabled', scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
