import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastService } from '../../shared/services/toast.service';
import { UserService } from '../user.service';
import { User } from '../../shared/model/user';

@Component({
  selector: 'app-user-register',
  templateUrl: './user-register.component.html',
  styleUrls: ['./user-register.component.scss']
})
export class UserRegisterComponent implements OnInit {

  user = new User();

  constructor(private router: Router, private userService: UserService, private toastService: ToastService) {
  }

  ngOnInit(): void {
  }

  onSubmit(): void {
    this.user.password = this.user.password1;
    this.userService.saveUser(this.user).subscribe(
      (response: any) => {
        if (response.error) {
          this.toastService.error(response.error);
        } else {
          this.toastService.success('Compte créé avec succès !');
          localStorage.setItem('menu-token', response.token);
          this.userService.isAuthenticated.next(true);
          this.router.navigate(['home']);
        }
      },
      () => this.toastService.error()
    );
  }

}
