import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';

@Injectable()
export class ToastService {
    constructor(private messageService: MessageService) {
    }

    success(summary?: string, detail?: string) {
        if (detail) {
            this.messageService.add({ severity: 'success', summary, detail });
        } else if (summary) {
            this.messageService.add({ severity: 'success', summary });
        } else {
            this.messageService.add({ severity: 'success', summary: 'Données enregistrées !' });
        }
    }

    error(summary?: string) {
        if (summary) {
            this.messageService.add({ severity: 'error', summary });
        } else {
            this.messageService.add({ severity: 'error', summary: 'Une erreur est survenue' });
        }
    }
}
