import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ApiService } from '../shared/services/api.service';

@Injectable()
export class LinkService extends ApiService {

    constructor(public http: HttpClient) {
        super(http, 'links');
    }

    savePositions(ids: number[]) {
        return this.http.post(environment.api + 'links/positions', { ids: ids });
    }

    associateImage(id: number, file: string) {
        return this.http.post(environment.api + `links/${id}/picture`, { image: file });
    }

}
