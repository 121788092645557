export class Period {
    id: number;
    dateBegin: Date;
    dateEnd: Date;
    timeBegin: Date | string;
    timeEnd: Date | string;
    timeBeginStr: string;
    timeEndStr: string;
    morning: boolean;
    noon: boolean;
    evening: boolean;
    days: number[];
    exceptPublicHolidays: boolean;

    constructor() {
        this.days = [0, 0, 0, 0, 0, 0, 0];
    }
}
