<div class="grid">
    <div class="col-12 md:col-6 lg:col-4 col-offset-4">
        <div class="box">

            <form (ngSubmit)="onSubmit()" #pageForm ngForm>
                <div class="p-field">

                    <div class="p-float-label">
                        <input type="text" pInputText [(ngModel)]="establishment.name" id="name" name="name" required
                            #name ngModel>
                        <label for="name">Nom</label>
                    </div>

                    <div class="p-float-label">
                        <input type="text" pInputText [(ngModel)]="establishment.alias" id="alias" name="alias" required
                            #alias ngModel>
                        <label for="alias">Identifiant (apparaissant dans l'URL)</label>
                    </div>

                    <div class="p-float-label">
                        <input type="text" pInputText [(ngModel)]="establishment.address" id="address" name="address"
                            required #address ngModel>
                        <label for="address">Adresse</label>
                    </div>

                    <div class="p-float-label">
                        <input type="text" pInputText [(ngModel)]="establishment.postalCode" id="postalCode"
                            name="postalCode" required #postalCode ngModel>
                        <label for="postalCode">Code postal</label>
                    </div>

                    <div class="p-float-label">
                        <input type="text" pInputText [(ngModel)]="establishment.city" id="city" name="city" required
                            #city ngModel>
                        <label for="city">Ville</label>
                    </div>

                    <div class="p-float-label">
                        <input type="text" pInputText [(ngModel)]="establishment.phone" id="phone" name="phone" required
                            #phone ngModel>
                        <label for="phone">Téléphone</label>
                    </div>

                </div>

                <p-button label="Enregistrer" type="submit"
                    [disabled]="!establishment.name || !establishment.alias || submitting"></p-button>
            </form>

        </div>
    </div>
</div>