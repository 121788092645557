import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MenuItem, PrimeIcons, PrimeNGConfig, SelectItem } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { EstablishmentFormComponent } from './modules/establishment/establishment-form/establishment-form.component';
import { EstablishmentService } from './modules/establishment/services/establishment.service';
import { Establishment } from './modules/shared/model/establishment';
import { User } from './modules/shared/model/user';
import { UserService } from './modules/user/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  isAuthenticated = false;
  showMenu = false;
  showUserPanel = false;
  desktop = false;
  deviceInfo = null;

  user: User;
  myEstablishments: SelectItem[] = [];
  myEstablishment: SelectItem;
  items: MenuItem[];
  faUserCircle = faUserCircle;

  constructor(private router: Router, private userService: UserService, private establishmentService: EstablishmentService, private dialogService: DialogService,
    private config: PrimeNGConfig, private deviceService: DeviceDetectorService) {
  }

  ngOnInit(): void {
    this.translate();

    this.desktop = this.deviceService.getDeviceInfo().deviceType === "desktop";

    if (!location.pathname.startsWith('/restaurant/') && !location.pathname.startsWith('/light/') && !location.pathname.startsWith('/tdb')) {
      this.initMenu();

      this.establishmentService.currentEstablishment.subscribe(establishment => {
        if (!establishment) {
          return;
        }
        this.showMenu = false;
        setTimeout(() => this.showMenu = true, 0);
        this.switchEstablishment(establishment.id);
      });
    }
  }

  initMenu() {
    this.items = [{ id: '1', label: 'Tableau de bord', styleClass: 'active' }, { id: '2', label: 'Informer' }, { id: '3', label: 'Diffuser' }, { id: '4', label: 'Synchroniser' }];

    this.userService.isLoggedIn().subscribe(data => {
      this.isAuthenticated = data;

      if (this.isAuthenticated) {
        this.showMenu = true;
        this.userService.getMe().subscribe((data: User) => {
          this.userService.user = data;
          this.user = data;
          this.showMenu = false;
          data.establishments.forEach(establishment => this.myEstablishments.push({ label: establishment.name, value: establishment.id }));
          this.myEstablishments.push({ label: 'Ajouter un établissement', value: -1, icon: PrimeIcons.PLUS });
          if (this.myEstablishments[0].value !== 0) {
            this.myEstablishment = this.myEstablishments[0];
            if (!location.pathname.startsWith('/restaurant/') && !location.pathname.startsWith('/admin')) {
              if (location.pathname.startsWith('/r/')) {
                this.switchEstablishment(+location.pathname.split('/')[2]);
              } else {
                this.switchEstablishment(this.myEstablishment.value);
              }
            }
          }
          setTimeout(() => this.showMenu = true, 0);
        });
      } else {
        if (!location.pathname.startsWith('/restaurant/')) {
          this.goHome();
        }
      }
    });
  }

  goHome(): void {
    if (this.userService.isAdmin()) {
      this.router.navigate(['admin']);
    } else {
      this.router.navigate(['home']);
    }
  }

  switchEstablishment(id: number): void {
    if (id === -1) {
      this.addEstablishment();
    } else {
      this.userService.establishment = this.userService.user.establishments.find(establishment => establishment.id === id);
      this.redirectTo('r/' + id);
    }
  }

  addEstablishment(): void {
    const ref = this.dialogService.open(EstablishmentFormComponent, {
      header: 'Ajouter un établissement',
      width: '70%',
      data: new Establishment()
    });

    ref.onClose.subscribe((establishment: Establishment) => {
      if (establishment) {
        this.userService.user.establishments.push(establishment);
        this.myEstablishments.splice(this.myEstablishments.length - 1, 0, { label: establishment.name, value: establishment.id });
        this.establishmentService.addEstablishment(establishment);
      }
    });
  }

  updateEstablishmentSection(sid: string) {
    const id = parseInt(sid);
    this.items.map(item => item.styleClass = '');
    this.items[id - 1].styleClass = 'active';
    this.establishmentService.updateSection(id);
  }

  logout() {
    this.showUserPanel = false;
    this.userService.logout();
    this.goHome();
  }

  displayMenu() {
    return this.desktop && this.isAuthenticated && !location.pathname.startsWith('/restaurant/') && !location.pathname.startsWith('/admin');
  }

  private translate() {
    this.config.setTranslation({
      "startsWith": "Starts with",
      "contains": "Contains",
      "notContains": "Not contains",
      "endsWith": "Ends with",
      "equals": "Equals",
      "notEquals": "Not equals",
      "noFilter": "No Filter",
      "lt": "Less than",
      "lte": "Less than or equal to",
      "gt": "Greater than",
      "gte": "Great then or equals",
      "is": "Is",
      "isNot": "Is not",
      "before": "Before",
      "after": "After",
      "clear": "Clear",
      "apply": "Apply",
      "matchAll": "Match All",
      "matchAny": "Match Any",
      "addRule": "Add Rule",
      "removeRule": "Remove Rule",
      "accept": "Yes",
      "reject": "No",
      "choose": "Choose",
      "upload": "Upload",
      "cancel": "Cancel",
      "dayNames": ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"],
      "dayNamesShort": ['dim', 'lun', 'mar', 'mer', 'jeu', 'ven', 'sam'],
      "dayNamesMin": ['DI', 'LU', 'MA', 'ME', 'JE', 'VE', 'SA'],
      "monthNames": ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
      "monthNamesShort": ['jan', 'fev', 'mar', 'avr', 'mai', 'jui', 'jul', 'aou', 'sep', 'oct', 'nov', 'dec'],
      "today": "Today",
      "weekHeader": "Wk"
    });
  }

  private redirectTo(uri: string) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([uri]));
  }

}

