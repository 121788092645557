import { Menu } from "../../menu/menu";
import { Dish } from "./dish";

export class MealCategory {
    id: number;
    label: string;
    dishes: Dish[];
    menu: Menu | string;
    position: number;

    constructor() {
        this.dishes = [];
    }
}