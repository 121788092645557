import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ToastService } from '../../shared/services/toast.service';
import { Link } from '../../shared/model/link';
import { LinkService } from '../link.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-link-form',
  templateUrl: './link-form.component.html',
  styleUrls: ['./link-form.component.scss']
})
export class LinkFormComponent implements OnInit {

  link: Link = new Link();
  // uploadUrl = `${environment.api}media_objects`;
  // mediaUrl = environment.mediaUrl;

  constructor(private linkService: LinkService, private toastService: ToastService, public ref: DynamicDialogRef,
    public config: DynamicDialogConfig) { }

  ngOnInit(): void {
    this.link = this.config.data;
  }

  onSubmit(): void {
    const link = JSON.parse(JSON.stringify(this.link));
    this.linkService.save(link).subscribe((link: Link) => this.toastService.success('Lien enregistré avec succès !'),
      () => this.toastService.error()
    );
  }

  // onUpload(event) {
  //   const file = event.originalEvent.body.filePath;
  //   this.linkService.associateImage(this.link.id, file).subscribe(() => this.link.image = { filePath: file });
  // }

}
