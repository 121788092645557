import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../../shared/services/api.service';

@Injectable()
export class EstablishmentClosureService extends ApiService {

    constructor(public http: HttpClient) {
        super(http, 'establishment_closures');
    }

}
