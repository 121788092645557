import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import bxsHomeHeart from '@iconify-icons/bx/bxs-home-heart';
import { DeviceDetectorService } from 'ngx-device-detector';
import { User } from '../shared/model/user';
import { UserService } from '../user/user.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HomeComponent implements OnInit {

  faSpinner = faSpinner;
  isAuthenticated = false;
  me: User;
  user = new User();
  submitting = false;
  loginError = false;
  loginErrorMessage = '';
  redirect = '';
  bxsHomeHeart = bxsHomeHeart;
  showResetPassword = false;

  constructor(private router: Router, private userService: UserService, private deviceService: DeviceDetectorService) {
    if (this.router.getCurrentNavigation() && this.router.getCurrentNavigation().extras.state) {
      this.redirect = this.router.getCurrentNavigation().extras.state.redirect;
      this.user.email = this.router.getCurrentNavigation().extras.state.email;
    }

    this.userService.isLoggedIn().subscribe(data => this.isAuthenticated = data);
  }

  ngOnInit(): void {
    if (this.isAuthenticated) {
      this.me = this.userService.user;
      if (this.me) {
        if (this.userService.isAdmin()) {
          this.router.navigate(['admin']);
        } else if (this.deviceService.getDeviceInfo().deviceType !== "desktop") {
          this.router.navigate(['light/' + this.me.id]);
        }
      }
    }
  }

  login() {
    this.submitting = true;
    this.userService.login({ username: this.user.email, password: this.user.password }).subscribe((data: { token: string, refresh_token: string }) => {
      localStorage.setItem('menu-token', data.token);
      localStorage.setItem('menu-refresh-token', data.refresh_token);
      this.userService.isAuthenticated.next(true);

      if (this.redirect) {
        this.router.navigate(['light/' + this.redirect]);
      } else {
        // todo : temporary
        location.reload();
      }
    }, error => {
      this.submitting = false;
      this.loginError = true;
      if (error.error && error.error.message === 'Invalid credentials.') {
        this.loginErrorMessage = 'Identifiants incorrects';
      } else if (error.statusText === "Unknown Error") {
        this.loginErrorMessage = 'Une erreur est survenue';
      }

      setTimeout(() => this.loginError = false, 2500);
    });
  }

  register() {
    this.router.navigate(['register']);
  }

  resetPassword() {
    console.log('rp');
  }

}
