import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Menu } from './menu';
import { MenuType } from '../shared/model/menu-type';
import { ApiService } from '../shared/services/api.service';

@Injectable()
export class MenuService extends ApiService {

    constructor(public http: HttpClient) {
        super(http, 'menus');
    }

    getNbMeals(menu: Menu) {
        menu.nbMeals = 0;
        if (menu.type === MenuType.MENU_DU_JOUR) {
            const yesterday = new Date();
            yesterday.setDate(yesterday.getDate() - 1);
            // menu.menuDays.filter(menuDay => new Date(menuDay.date) > yesterday).forEach(menuDay =>
            //     menuDay.categories.forEach(category => menu.nbMeals += category.dishes.length));
        } else {
            menu.categories.forEach(category => menu.nbMeals += category.dishes ? category.dishes.length : 0);
        }
    }

    savePositions(ids: number[]) {
        return this.http.post(environment.api + this.path + '/positions', { ids: ids });
    }

    getMenuDay(date: string) {
        return this.http.get(environment.api + this.path + '/menu_day/' + date);
    }

    getMenuDays(id: number) {
        return this.http.get(environment.api + this.path + '/menu_days/' + id);
    }

}
