import { Catalog } from "./catalog";
import { Product } from "./product";

export class ProductCategory {
    id: number;
    label?: string;
    description?: string;
    position?: number;
    products: Product[];
    catalog: Catalog | string;
    category: ProductCategory | string;
    categories: ProductCategory[];
}
