import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ApiService } from '../shared/services/api.service';

@Injectable()
export class MealCategoryService extends ApiService {

    constructor(public http: HttpClient) {
        super(http, 'meal_categories');
    }

    savePositions(ids: number[]) {
        return this.http.post(environment.api + this.path + '/positions', { ids: ids });
    }

}
