import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MealLabel } from '../shared/model/meal-label';
import { of } from 'rxjs';
import { MealColor } from '../shared/model/meal-color';
import { MealAllergen } from '../shared/model/meal-allergen';
import { ApiService } from '../shared/services/api.service';

@Injectable()
export class MealService extends ApiService {

    labels: MealLabel[];
    allergens: MealAllergen[];
    colors: MealColor[];

    constructor(public http: HttpClient) {
        super(http, 'meals');
    }

    remove(categoryId: number, mealId: number) {
        return this.http.post(environment.api + 'meals/' + mealId + '/remove', { categoryId: categoryId });
    }

    associateImage(id: number, file: string) {
        return this.http.post(environment.api + `meals/${id}/picture`, { image: file });
    }

    removePicture(id: number) {
        return this.http.delete(environment.api + `meals/${id}/picture`);
    }

    getLabels() {
        if (this.labels) {
            return of(this.labels);
        }
        return this.http.get(environment.api + `meal_labels`);
    }

    getAllergens() {
        if (this.allergens) {
            return of(this.allergens);
        }
        return this.http.get(environment.api + `meal_allergens`);
    }

    getColors() {
        if (this.colors) {
            return of(this.colors);
        }
        return this.http.get(environment.api + `meal_colors`);
    }

    savePositions(ids: number[]) {
        return this.http.post(environment.api + 'meals/positions', { ids: ids });
    }

}
