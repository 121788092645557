import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Menu } from '../../menu/menu';
import { MealCategory } from '../../shared/model/meal-category';
import { ToastService } from '../../shared/services/toast.service';
import { MealCategoryService } from '../meal-category.service';

@Component({
  selector: 'app-meal-category-form',
  templateUrl: './meal-category-form.component.html',
  styleUrls: ['./meal-category-form.component.scss']
})
export class MealCategoryFormComponent implements OnInit {

  mealCategory: MealCategory = new MealCategory();
  menu: Menu;

  constructor(private mealCategoryService: MealCategoryService, private toastService: ToastService, public config: DynamicDialogConfig,
    public ref: DynamicDialogRef) { }

  ngOnInit(): void {
    this.mealCategory = this.config.data.mealCategory;
    this.menu = this.config.data.menu;
  }

  onSubmit(): void {
    if (!this.menu.id) {
      this.ref.close(this.mealCategory);
    } else {
      const category = JSON.parse(JSON.stringify(this.mealCategory));
      delete category.dishes;
      this.mealCategoryService.save(category).subscribe(
        (m: MealCategory) => {
          this.mealCategory.id = m.id;
          this.mealCategory.label = m.label;
          this.toastService.success('Catégorie enregistrée avec succès !');
          this.ref.close(this.mealCategory);
        },
        () => this.toastService.error()
      );
    }
  }

}
