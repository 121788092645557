<div id="content" *ngIf="establishment">

    <div id="menu-header">
        <fa-icon [icon]="faChevronLeft" size="2x" (click)="back()" class="pointer"></fa-icon>

        <span id="contact-logo" *ngIf="establishment.logo">
            <p-avatar image="{{ mediaUrl }}/{{ establishment.logo.filePath }}" styleClass="p-mr-2" size="xlarge"
                shape="circle"></p-avatar>
        </span>

        <span>
            <strong>{{ establishment.name }}</strong><br>
            {{ establishment.address }}, {{ establishment.postalCode }} {{ establishment.city }}
        </span>
    </div>

    <div id="tabs">
        <div class="tab" (click)="showCarte()" [ngClass]="{'active' : displayCarte}">
            <fa-icon [icon]="faUtensils"></fa-icon> Carte
        </div>
        <div class="tab" (click)="showFormules()" [ngClass]="{'active' : displayFormules}">
            <fa-icon [icon]="faEuroSign"></fa-icon> Formules
        </div>
    </div>

    <div id="carte" *ngIf="displayCarte">
        Plats

        <p-carousel [value]="visibleMeals" [responsiveOptions]="responsiveOptions">
            <ng-template let-meal pTemplate="item">
                <div class="plat-item">
                    <div class="plat-item-content">
                        <div class="plat-item-content-title">{{ meal.label }}</div>
                        <div class="plat-item-content-description">{{ meal.description }}</div>
                        <div>
                            <span *ngFor="let item of meal.labels" class="plat-item-content-label">
                                {{ item.label }}
                            </span>
                        </div>
                        <div class="plat-item-content-price">{{ meal.price | currency :'EUR' }}</div>
                    </div>
                </div>
            </ng-template>
        </p-carousel>

        <div id="filters" *ngIf="filters.length>1">
            <fa-icon [icon]="faSearch"></fa-icon>
            <span *ngFor="let filter of filters" class="filter" (click)="filterMeals(filter)">{{ filter.label }}</span>
        </div>

    </div>

    <div id="formules" *ngIf="displayFormules">
        Ordre : Alphabétique - Prix

        <div class="card" *ngFor="let formule of formules">
            <div class="formule-title">{{ formule.label }} - {{ formule.price | currency :'EUR' }}</div>
            <div class="formule-period">{{ formule.period }}</div>
            <div class="formule-description">{{ formule.description }}</div>
        </div>
    </div>
</div>




<div id="establishment-bottom-bar" *ngIf="establishment">
    <a href="tel:{{ establishment.phone }}">
        <i class="pi pi-phone" style="font-size: 1.2rem;margin-right: 3px"></i>&nbsp;
        {{ establishment.phone | phoneNumber }}
    </a>
</div>