<div *ngIf="displayMenu()">

    <p-menubar *ngIf="showMenu">

        <ng-template pTemplate="start">
            <a id="menu-menu-logo" (click)="goHome()">My Placy</a>

            <p-dropdown *ngIf="myEstablishment" class="right" [options]="myEstablishments" [(ngModel)]="myEstablishment"
                (onChange)="switchEstablishment(myEstablishment)">
                <ng-template let-object pTemplate="item">
                    <i class="{{ object.icon }}"></i> {{ object.label }}
                </ng-template>
            </p-dropdown>
        </ng-template>

        <ng-template pTemplate="end">
            <span *ngFor="let item of items" class="section-menu" [ngClass]="item.styleClass"
                (click)="updateEstablishmentSection(item.id)">
                <div>
                    {{ item.label }}
                </div>
            </span>

            <fa-icon id="myAccount" [icon]="faUserCircle" (click)="showUserPanel=true"></fa-icon>
        </ng-template>

    </p-menubar>

    <div id="content">
        <router-outlet></router-outlet>
    </div>
</div>

<ng-container *ngIf="!displayMenu()">
    <router-outlet></router-outlet>
</ng-container>

<p-toast></p-toast>

<p-sidebar [(visible)]="showUserPanel" position="right" *ngIf="user" id="sidebar-user">

    <div id="sidebar-icon">
        <fa-icon [icon]="faUserCircle"></fa-icon>
    </div>

    <div id="sidebar-username">{{ user.firstname }} {{ user.lastname }}</div>

    <button *ngIf="isAuthenticated" (click)="logout()" pButton label="Déconnexion" icon="pi pi-power-off"
        class="p-button-danger"></button>

</p-sidebar>