import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Establishment } from '../../shared/model/establishment';
import { ToastService } from '../../shared/services/toast.service';
import { EstablishmentService } from '../services/establishment.service';

@Component({
  selector: 'app-establishment-form',
  templateUrl: './establishment-form.component.html',
  styleUrls: ['./establishment-form.component.scss']
})
export class EstablishmentFormComponent implements OnInit {

  establishment: Establishment = new Establishment();
  submitting = false;

  constructor(private establishmentService: EstablishmentService, public ref: DynamicDialogRef, public config: DynamicDialogConfig,
    private toastService: ToastService) { }

  ngOnInit(): void {
    this.establishment = this.config.data;
  }

  onSubmit(): void {
    this.submitting = true;
    if (!this.establishment.alias) {
      this.toastService.error("Veuillez renseigner un identifiant");
      return;
    }
    this.establishmentService.save(this.establishment).subscribe(data => this.ref.close(data), () => this.submitting = false);
  }

}
