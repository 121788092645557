import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Period } from '../shared/model/period';
import { UtilsService } from '../shared/services/utils.service';

@Injectable()
export class PeriodService {

    private path = 'periods';

    constructor(public http: HttpClient, private utilsService: UtilsService) {
    }

    initializePeriod(period: Period) {
        if (!period) {
            period = new Period();
        }
        if (!period.days) {
            period.days = [0, 0, 0, 0, 0, 0, 0];
        }
        period.days = period.days.map(Number);
        if (period.dateBegin) {
            period.dateBegin = new Date(period.dateBegin);
        }
        if (period.dateEnd) {
            period.dateEnd = new Date(period.dateEnd);
        }
        if (period.timeBegin) {
            period.timeBeginStr = this.utilsService.dateToHourAndMinute(period.timeBegin);
        }
        if (period.timeEnd) {
            period.timeEndStr = this.utilsService.dateToHourAndMinute(period.timeEnd);
        }
        return period;
    }

    save(data: Period) {
        if (data.id) {
            return this.put(data);
        } else {
            return this.post(data);
        }
    }

    private post(data: Period) {
        return this.http.post(environment.api + this.path, this.formatPeriod(data));
    }

    private put(data: Period) {
        return this.http.put(environment.api + this.path + '/' + data.id, this.formatPeriod(data));
    }

    private formatPeriod(period: Period) {
        if (period.dateBegin) {
            period.dateBegin = new Date(period.dateBegin);
            period.dateBegin.setDate(period.dateBegin.getDate() + 1);
        }
        if (period.dateEnd) {
            period.dateEnd = new Date(period.dateEnd);
            period.dateEnd.setDate(period.dateEnd.getDate() + 1);
        }
        return period;
    }

}
