import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ApiService } from '../../shared/services/api.service';
import { Partner } from '../../shared/model/partner';
import { of } from 'rxjs';

@Injectable()
export class PartnerService extends ApiService {

    partners: Partner[];
    
    constructor(public http: HttpClient) {
        super(http, 'partners')
    }

    getPartners() {
        if (this.partners) {
            return of(this.partners);
        }
        return this.http.get(environment.api + `partners`);
    }

}
