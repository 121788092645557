import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../../shared/services/api.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class EstablishmentPublicHolidayService extends ApiService {

    constructor(public http: HttpClient) {
        super(http, 'establishment_public_holidays');
    }

    closePublicHoliday(id: number, establishmentId: number) {
        return this.http.post(environment.api + this.path + '/close', { id, establishmentId });
    }

}
