import { Menu } from "../../menu/menu";
import { Link } from "./link";
import { EstablishmentAward } from "./establishment-award";
import { EstablishmentClosure } from "./establishment-closure";
import { EstablishmentEquipment } from "./establishment-equipment";
import { EstablishmentLabel } from "./establishment-label";
import { EstablishmentHoliday } from "./establishment-holiday";
import { EstablishmentPayment } from "./establishment-payment";
import { EstablishmentServiceIncluded } from "./establishment-service-included";
import { EstablishmentTool } from "./establishment-tool";
import { EstablishmentType } from "./establishment-type";
import { Schedule } from "./schedule";
import { Opinion } from "./opinion";
import { Tag } from "./tag";
import { Catalog } from "./catalog";
import { EstablishmentSection } from "./establishment-section";
import { ProductCategory } from "./product-category";

export class Establishment {
    id: number;
    alias: string;
    name: string;
    address: string;
    postalCode: string;
    city: string;
    phone: string;
    email: string;
    lat: number;
    lng: number;
    covers: any;
    team: any;
    chief: any;
    logo: any;
    schedules: Schedule[];
    links: Link[];
    instagram: string;
    facebook: string;
    google: string;
    tripadvisor: string;
    tools: EstablishmentTool[];
    opened: boolean;
    about: string;
    type: string;
    awards: EstablishmentAward[];
    types: EstablishmentType[];
    typeLabels: string[];
    equipments: EstablishmentEquipment[];
    equipmentLabels: string[];
    labels: EstablishmentLabel[];
    labelLabels: string[];
    services: EstablishmentServiceIncluded[];
    serviceLabels: string[];
    payments: EstablishmentPayment[];
    paymentLabels: string[];
    slug: string;
    newsMessage: string;
    title: string;
    welcomeWord: string;
    guestsNumber: number;
    photos: any[];
    selectedEquipments: number[];
    selectedServices: number[];
    selectedPayments: number[];
    selectedLabels: number[];
    selectedTypes: number[];
    menus: Menu[];
    catalogs: Catalog[];
    establishmentHolidays: EstablishmentHoliday[];
    establishmentClosures: EstablishmentClosure[];
    mainColor: string;
    font: string;
    background: string;
    peopleTitle: string;
    peopleName: string;
    peopleDescription: string;
    giftLink: string;
    giftMessage: string;
    opinions: Opinion[];
    tags: Tag[];
    sections: EstablishmentSection[];
    productCategories: ProductCategory[];

    partners: Establishment[];
    deliveryLinks: EstablishmentTool[];
    bookingLinks: EstablishmentTool[];
    orderLinks: EstablishmentTool[];
    backgroundColor: string;
    backgroundColor2: string;
    backgroundColor3: string;
    domain: string;
    placyType: number;
    created: Date;

    constructor() {
        this.schedules = [];
        this.links = [];
        this.tools = [];
        this.equipments = [];
        this.equipmentLabels = [];
        this.services = [];
        this.serviceLabels = [];
        this.payments = [];
        this.paymentLabels = [];
        this.photos = [];
        this.covers = [];
        this.selectedEquipments = [];
        this.selectedServices = [];
        this.selectedPayments = [];
        this.menus = [];
        this.establishmentHolidays = [];
        this.establishmentClosures = [];
        this.tags = [];
        this.sections = [];
    }
}