import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LOCALE_ID, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AvatarModule } from 'primeng/avatar';
import { ButtonModule } from 'primeng/button';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { DropdownModule } from 'primeng/dropdown';
import { EditorModule } from 'primeng/editor';
import { InputTextModule } from 'primeng/inputtext';
import { MenubarModule } from 'primeng/menubar';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ToastModule } from 'primeng/toast';

import { HomeComponent } from './modules/home/home.component';
import { FormsModule } from '@angular/forms';
import { LinkModule } from './modules/link/link.module';
import { LinkService } from './modules/link/link.service';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './modules/shared/auth-interceptor';
import { UserModule } from './modules/user/user.module';
import { MessageService } from 'primeng/api';
import { ToastService } from './modules/shared/services/toast.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { EstablishmentService } from './modules/establishment/services/establishment.service';
import { ScheduleService } from './modules/establishment/services/schedule.service';

import { EstablishmentModule } from './modules/establishment/establishment.module';
import { LinkFormComponent } from './modules/link/link-form/link-form.component';
import { EstablishmentFormComponent } from './modules/establishment/establishment-form/establishment-form.component';
import { UserRegisterComponent } from './modules/user/user-register/user-register.component';
import localeFr from '@angular/common/locales/fr';
import { registerLocaleData } from '@angular/common';
import { MealService } from './modules/meal/meal.service';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { SafeHtmlPipe } from './modules/shared/pipes/safeHtml.pipe';
import { EstablishmentDisplayMenuComponent } from './modules/establishment/establishment-display-menu/establishment-display-menu.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { EstablishmentClosureService } from './modules/establishment/services/establishment-closure.service';
import { MealCategoryService } from './modules/meal/meal-category.service';
import { MealCategoryFormComponent } from './modules/meal/meal-category-form/meal-category-form.component';
import { EstablishmentPublicHolidayService } from './modules/establishment/services/establishment-public-holiday.service';
import { MenuService } from './modules/menu/menu.service';
import { FormuleService } from './modules/formule/formule.service';
import { MealPriceService } from './modules/meal/meal-price.service';
import { DishService } from './modules/meal/dish.service';
import { PeriodService } from './modules/meal/period.service';
import { FormulePriceService } from './modules/meal/formule-price.service';
import { MenuDayService } from './modules/meal/menu-day.service';
import { EstablishmentToolService } from './modules/establishment/services/establishment-tool.service';
import { EstablishmentHolidayService } from './modules/establishment/services/establishment-holiday.service';
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';
import { RouterModule } from '@angular/router';
import { SidebarModule } from 'primeng/sidebar';
import { IconModule } from '@visurel/iconify-angular';
import { PartnerService } from './modules/establishment/services/partner.service';
import { UtilsService } from './modules/shared/services/utils.service';
import { OpinionService } from './modules/establishment/services/opinion.service';
import { TagService } from './modules/meal/tag.service';
import { ProductCategoryFormComponent } from './modules/catalog/product-category-form/product-category-form.component';
import { ProductCategoryService } from './modules/catalog/product-category.service';
import { ProductService } from './modules/catalog/product.service';
import { CatalogService } from './modules/catalog/catalog.service';
import { SectionService } from './modules/section/section.service';
import { ManagementService } from './modules/user/management.service';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LinkFormComponent,
    MealCategoryFormComponent,
    EstablishmentDisplayMenuComponent,
    EstablishmentFormComponent,
    UserRegisterComponent,
    SafeHtmlPipe,
    ProductCategoryFormComponent,
  ],
  exports: [
    SafeHtmlPipe,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    FontAwesomeModule,
    IconModule,
    RouterModule,

    LinkModule,
    EstablishmentModule,
    UserModule,

    // PrimeNG
    AvatarModule,
    ButtonModule,
    DropdownModule,
    DynamicDialogModule,
    EditorModule,
    InputTextModule,
    MenubarModule,
    MessageModule,
    MessagesModule,
    ProgressSpinnerModule,
    OverlayPanelModule,
    SidebarModule,
    ToastModule,

    NgxGoogleAnalyticsModule.forRoot('G-NER2XVS80C'),


    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  providers: [
    CatalogService,
    DialogService,
    DishService,
    EstablishmentClosureService,
    EstablishmentHolidayService,
    EstablishmentPublicHolidayService,
    EstablishmentService,
    EstablishmentToolService,
    FormulePriceService,
    FormuleService,
    LinkService,
    ManagementService,
    MealService,
    MealCategoryService,
    MealPriceService,
    MenuDayService,
    MenuService,
    OpinionService,
    PartnerService,
    PeriodService,
    ProductCategoryService,
    ProductService,
    ScheduleService,
    SectionService,
    TagService,
    UtilsService,
    HttpClient,
    MessageService,
    ToastService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: LOCALE_ID,
      useValue: 'fr-FR'
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

registerLocaleData(localeFr);
