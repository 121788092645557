import { Establishment } from "./establishment";

export class Link {
    id: number;
    label: string;
    url: string;
    position: number;
    establishment: Establishment | string;
    image: any;
}
