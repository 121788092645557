<div class="grid">
    <div class="col-12 md:col-6 lg:col-4 col-offset-3">
        <div class="box">

            <h3>Créer un compte</h3>
            <br>

            <form (ngSubmit)="onSubmit()" #pageForm ngForm>
                <div class="p-field">
                    <div class="p-float-label">
                        <input type="text" pInputText [(ngModel)]="user.firstname" id="firstname" name="firstname"
                        required #firstname ngModel>
                        <label for="firstname">Prénom</label>
                    </div>
                </div>
                <div class="p-field">
                    <div class="p-float-label">
                        <input type="text" pInputText [(ngModel)]="user.lastname" id="lastname" name="lastname" required
                        #lastname ngModel>
                        <label for="firstname">Nom</label>
                    </div>
                </div>
                <div class="p-field">
                    <div class="p-float-label">
                        <input type="text" pInputText [(ngModel)]="user.email" id="email" name="email" required #email
                            ngModel email="true">
                        <label for="email">Mail</label>
                    </div>
                </div>
                <div class="p-field">
                    <div class="p-float-label">
                        <input type="password" pInputText [(ngModel)]="user.password1" id="password1" name="password1"
                            required #password1 ngModel>
                        <label for="password1">Mot de passe</label>
                    </div>
                </div>
                <div class="p-field">
                    <div class="p-float-label">
                        <input type="password" pInputText [(ngModel)]="user.password2" id="password2" name="password2"
                            required #password2 ngModel>
                        <label for="password2">Confirmation du mot de passe</label>
                    </div>
                </div>
                <p-button label="Enregistrer" type="submit"
                    [disabled]="!user.email || !user.password1 || !user.password2"></p-button>
            </form>

        </div>
    </div>
</div>