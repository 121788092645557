import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { User } from '../shared/model/user';
import { BehaviorSubject, Observable } from 'rxjs';
import { Establishment } from '../shared/model/establishment';
import { Meal } from '../shared/model/meal';
import { MealPrice } from '../shared/model/meal-price';

@Injectable()
export class UserService {

    user: User;
    establishment: Establishment;
    extraLabels: string[];

    public isAuthenticated = new BehaviorSubject<boolean>(this.hasToken());;

    constructor(public http: HttpClient) {
    }

    isAdmin() {
        return this.user && this.user.roles && this.user.roles.includes('ROLE_ADMIN');
    }

    saveUser(user: User) {
        return this.http.post(environment.api + 'register', user);
    }

    getUsers() {
        return this.http.get(environment.api + 'users.json');
    }

    getMe() {
        return this.http.get(environment.api + 'me');
    }

    getUserRoles() {
        return this.http.get(environment.api + 'user/roles');
    }

    login(user) {
        return this.http.post(environment.api + 'login', user);
    }

    deleteUser(id: number) {
        return this.http.delete(environment.api + `users/${id}`);
    }

    logout() {
        localStorage.removeItem('menu-token');
        localStorage.removeItem('menu-refresh-token');
        if (this.isAuthenticated) {
            this.isAuthenticated.next(false);
        }
    }

    getEmail(key: string) {
        return this.http.get(environment.api + `user/${key}`);
    }

    private hasToken(): boolean {
        let token = localStorage.getItem('menu-token');

        if (!!token) {
            let expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
            const now = Math.floor((new Date).getTime() / 1000);

            if (expiry < now) {
                this.logout();
            } else if (expiry - now < 86400) { // renew token if expiration in less than 1 day
                const refreshToken = localStorage.getItem("menu-refresh-token")
                this.http.post(environment.api.replace('/api', '') + 'token/refresh', { refresh_token: refreshToken }).subscribe((data: { token: string, refresh_token: string }) => {
                    localStorage.setItem('menu-token', data.token);
                    localStorage.setItem('menu-refresh-token', data.refresh_token);
                });
            }
            return now < expiry;
        }
        return false;
    }

    /**
    *
    * @returns {Observable<T>}
    */
    isLoggedIn(): Observable<boolean> {
        return this.isAuthenticated.asObservable();
    }

    getExtraLabels() {
        if (!this.extraLabels) {
            this.extraLabels = [];
            this.establishment.menus.forEach(menu => menu.categories.forEach(category => category.dishes.forEach(dish =>
                (dish.meal as Meal).prices.filter((price: MealPrice) => price.type === 2).forEach((price: MealPrice) => this.extraLabels.push(price.label)))));
        }
        return this.extraLabels;
    }

}
