<div class="grid">
    <div class="col-12 md:col-6 lg:col-4 col-offset-3">
        <div class="box">

            <br>

            <form (ngSubmit)="onSubmit()" #linkForm ngForm>

                <div class="p-field">
                    <div class="p-float-label">
                        <input type="text" pInputText [(ngModel)]="link.label" id="label" name="label" required #label
                            ngModel>
                        <label for="label">Label</label>
                    </div>
                </div>

                <div class="p-field">
                    <div class="p-float-label">
                        <input type="text" pInputText [(ngModel)]="link.url" id="url" name="url" required
                            #url="ngModel">
                        <label for="url">Lien</label>
                    </div>
                </div>

                <!-- <div *ngIf="link.image">
                    <img src="{{ mediaUrl }}/{{ link.image.filePath }}" height="100" />
                </div>
                <p-fileUpload *ngIf="link.id" chooseLabel="{{ link.image ? 'Modifier l\'image' : 'Ajouter une image' }}"
                    mode="basic" name="file" url="{{ uploadUrl }}" accept="image/*" maxFileSize="1000000" [auto]="true"
                    (onUpload)="onUpload($event)"></p-fileUpload> -->
                <br>

                <p-button label="Enregistrer" type="submit"></p-button>

            </form>

        </div>
    </div>
</div>