<div class="grid">
    <div class="col-12 md:col-6 lg:col-4 col-offset-3">
        <div class="box">

            <br>

            <form (ngSubmit)="onSubmit()" #mealCategoryForm ngForm>

                <label class="custom-label" for="label">Nom de la catégorie</label>
                <input type="text" pInputText [(ngModel)]="mealCategory.label" id="label" name="label" required #label
                    ngModel>

                <br><br>

                <p-button label="Enregistrer" type="submit"></p-button>

            </form>

        </div>
    </div>
</div>