import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Establishment } from '../../shared/model/establishment';
import { BehaviorSubject, of } from 'rxjs';
import { EstablishmentEquipment } from '../../shared/model/establishment-equipment';
import { EstablishmentPayment } from '../../shared/model/establishment-payment';
import { EstablishmentServiceIncluded } from '../../shared/model/establishment-service-included';
import { ApiService } from '../../shared/services/api.service';
import { EstablishmentTool } from '../../shared/model/establishment-tool';
import { Award } from '../../shared/model/award';
import { EstablishmentType } from '../../shared/model/establishment-type';
import { EstablishmentLabel } from '../../shared/model/establishment-label';

@Injectable()
export class EstablishmentService extends ApiService {
    private newEstablishment = new BehaviorSubject(null);
    currentEstablishment = this.newEstablishment.asObservable();

    private changeSection = new BehaviorSubject(null);
    currentSection = this.changeSection.asObservable();

    equipments: EstablishmentEquipment[];
    labels: EstablishmentLabel[];
    services: EstablishmentServiceIncluded[];
    payments: EstablishmentPayment[];
    awards: Award[];
    types: EstablishmentType[];

    establishment: Establishment;

    constructor(public http: HttpClient) {
        super(http, 'establishments');
    }

    setDomain(data: any) {
        return this.http.put(environment.api + this.path + '/admin/' + data.id, data);
    }

    get() {
        return this.http.get(environment.api + this.path + '/admin/all');
    }

    getById(establishmentId: number) {
        return this.http.get(environment.api + this.path + '/' + establishmentId + '/data');
    }

    updateAlias(establishmentId: number, alias: string) {
        return this.http.put(environment.api + this.path + '/' + establishmentId + '/alias', { alias });
    }

    updateSection(sectionId: number) {
        this.changeSection.next(sectionId);
    }

    addEstablishment(establishment: Establishment) {
        this.newEstablishment.next(establishment);
    }

    getBySlug(slug: string) {
        if (this.establishment) {
            return of(this.establishment);
        }
        return this.http.get(environment.publicApi + this.path + `/${slug}`);
    }

    getFileData(id: number, slug: string) {
        // return this.http.get(environment.publicApi + this.path + `/${id}/${slug}`);
        return this.http.get(environment.jsonPath + id + '-' + slug + '.json');
    }

    getStatus(id: number) {
        return this.http.get(environment.publicApi + this.path + `/${id}/status`);
    }

    upload(file) {
        return this.http.post(environment.api + `media_objects`, file);
    }

    associateImage(id: number, file: string, type: string) {
        return this.http.post(environment.api + this.path + `/${id}/picture`, { image: file, type });
    }

    deletePhoto(id: number) {
        return this.http.delete(environment.api + `establishment_photos/${id}`);
    }

    getEquipments() {
        if (this.equipments) {
            return of(this.equipments);
        }
        return this.http.get(environment.api + `establishment_equipments`);
    }

    getLabels() {
        if (this.labels) {
            return of(this.labels);
        }
        return this.http.get(environment.api + `establishment_labels`);
    }

    getServices() {
        if (this.services) {
            return of(this.services);
        }
        return this.http.get(environment.api + `establishment_services`);
    }

    getPayments() {
        if (this.payments) {
            return of(this.payments);
        }
        return this.http.get(environment.api + `establishment_payments`);
    }

    getTypes() {
        if (this.types) {
            return of(this.types);
        }
        return this.http.get(environment.api + `establishment_types`);
    }

    getAwards() {
        if (this.awards) {
            return of(this.awards);
        }
        return this.http.get(environment.api + `awards`);
    }

    deleteAward(id: number) {
        return this.http.delete(environment.api + `establishment_awards/${id}`);
    }

    deleteTool(id: number) {
        return this.http.delete(environment.api + `establishment_tools/${id}`);
    }

    getPublicHolidays(id: number) {
        return this.http.get(environment.publicApi + this.path + `/${id}/publicHolidays`);
    }

    private hexToRgb(hex: string, opacity: number) {
        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex.trim());
        return result ? 'rgba(' + parseInt(result[1], 16) + ',' + parseInt(result[2], 16) + ',' + parseInt(result[3], 16) + ',' + opacity + ')' : null;
    }

    updateColors(establishment: Establishment) {
        if (establishment.mainColor) {
            establishment.backgroundColor = this.hexToRgb(establishment.mainColor, 0.2);
            establishment.backgroundColor2 = this.hexToRgb(establishment.mainColor, 0.5);
            establishment.backgroundColor3 = this.hexToRgb(establishment.mainColor, 0.1);
        }
        return establishment;
    }

    getTools(establishment: Establishment) {
        establishment.bookingLinks = [];
        establishment.orderLinks = [];
        establishment.deliveryLinks = [];

        establishment.tools.forEach((tool: EstablishmentTool) => {
            if (tool.type === 1) {
                establishment.bookingLinks.push(tool);
            } else if (tool.type === 2) {
                establishment.orderLinks.push(tool);
            } else if (tool.type === 3) {
                establishment.deliveryLinks.push(tool);
            }
        });

        return establishment;
    }

}
