import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Catalog } from '../../shared/model/catalog';
import { ProductCategory } from '../../shared/model/product-category';
import { ToastService } from '../../shared/services/toast.service';
import { ProductCategoryService } from '../product-category.service';

@Component({
  selector: 'app-product-category-form',
  templateUrl: './product-category-form.component.html',
  styleUrls: ['./product-category-form.component.scss']
})
export class ProductCategoryFormComponent implements OnInit {

  productCategory: ProductCategory = new ProductCategory();
  catalog: Catalog;

  constructor(private productCategoryService: ProductCategoryService, private toastService: ToastService, public config: DynamicDialogConfig,
    public ref: DynamicDialogRef) { }

  ngOnInit(): void {
    this.productCategory = this.config.data.productCategory;
    this.catalog = this.config.data.catalog;
  }

  onSubmit(): void {
    if (!this.catalog.id) {
      this.ref.close(this.productCategory);
    } else {
      const category = JSON.parse(JSON.stringify(this.productCategory));
      delete category.products;
      this.productCategoryService.save(category).subscribe(
        (m: ProductCategory) => {
          this.productCategory.id = m.id;
          this.productCategory.label = m.label;
          this.toastService.success('Catégorie enregistrée avec succès !');
          this.ref.close(this.productCategory);
        },
        () => this.toastService.error()
      );
    }
  }

}
