import { Injectable } from '@angular/core';
import { Period } from '../model/period';

@Injectable()
export class UtilsService {

    days = ['lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi', 'dimanche'];

    constructor() {
    }

    getPeriodPeriodLabel(period: Period) {
        if (period.dateBegin && period.dateEnd) {
            return 'Du ' + new Date(period.dateBegin).toLocaleDateString() + ' au ' + new Date(period.dateEnd).toLocaleDateString();
        } else if (period.dateBegin) {
            return 'À partir du ' + new Date(period.dateBegin).toLocaleDateString();
        } else if (period.dateEnd) {
            return `Jusqu'au ` + new Date(period.dateEnd).toLocaleDateString();
        }
    }

    getDaysPeriodLabel(days: number[]) {
        let label = '';
        let labelDays = [];
        for (let i = 0; i < 7; i++) {
            if (days[i]) {
                labelDays.push(this.days[i]);
            }
        }
        if (labelDays.length === 1) {
            label = labelDays[0];
        } else {
            let firstOne = days.indexOf(1);
            let lastOne = days.lastIndexOf(1);
            if (lastOne - firstOne + 1 === labelDays.length) {
                label = 'Du ' + this.days[firstOne] + ' au ' + this.days[lastOne];
            } else {
                label = labelDays.join(', ');
            }
        }
        if (!label) {
            return '';
        }
        return label[0].toUpperCase() + label.slice(1);
    }

    getMomentDayPeriodLabel(period: Period) {
        if (!period) {
            return '';
        }
        if (period.morning && period.noon && period.evening) {
            return 'Matin, Midi & Soir';
        }
        if (period.morning && period.noon && !period.evening) {
            return 'Matin & Midi';
        }
        if (period.morning && !period.noon && period.evening) {
            return 'Matin & Soir';
        }
        if (period.morning && !period.noon && !period.evening) {
            return 'Matin';
        }
        if (period.noon && period.evening) {
            return 'Midi & Soir';
        }
        if (period.noon && !period.evening) {
            return 'Midi';
        }
        if (!period.noon && period.evening) {
            return 'Soir';
        }
    }

    timeStrToDate(time: string) {
        let tmp = time.split(':');
        let date = new Date();
        date.setUTCHours(+tmp[0]);
        date.setMinutes(+tmp[1]);
        return date;
    }

    formatPublicHolidays(publicHolidays: any[], utc = false) {
        publicHolidays.forEach(item => {
            item.status = -1; // non renseigné
            if (item.times.length > 0) {
                item.status = item.times.filter(time => time.status).length > 0 ? 1 : 0;
            }
            item.times.forEach(time => {
                time.timeBegin = new Date(time.timeBegin);
                time.timeEnd = new Date(time.timeEnd);
                time.timeBeginStr = this.dateToHourAndMinute(time.timeBegin, utc);
                time.timeEndStr = this.dateToHourAndMinute(time.timeEnd, utc);
            });
        });
        return publicHolidays;
    }

    dateToHourAndMinute(date, utc = false) {
        date = new Date(date);
        const hours = utc ? date.getUTCHours().toString() : date.getHours().toString();
        return `${(`${"0" + hours}`).slice(-2)}:${("0" + date.getMinutes().toString()).slice(-2)}`;
    }

    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

}
