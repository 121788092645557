import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faChevronLeft, faUtensils, faEuroSign, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Establishment } from '../../shared/model/establishment';
import { Location } from '@angular/common'
import { environment } from 'src/environments/environment';
import { MealCategory } from '../../shared/model/meal-category';

@Component({
  selector: 'app-establishment-display-menu',
  templateUrl: './establishment-display-menu.component.html',
  styleUrls: ['./establishment-display-menu.component.scss']
})
export class EstablishmentDisplayMenuComponent implements OnInit {

  establishment: Establishment;
  mediaUrl = environment.mediaUrl;
  displayCarte = true;
  displayFormules = false;

  faChevronLeft = faChevronLeft;
  faEuroSign = faEuroSign;
  faUtensils = faUtensils;
  faSearch = faSearch;

  plats = [];
  visibleMeals = [];
  formules = [];
  filters = [];
  responsiveOptions: { breakpoint: string; numVisible: number; numScroll: number; }[];

  constructor(private router: Router, private location: Location) {
    if (this.router.getCurrentNavigation().extras.state) {
      this.establishment = this.router.getCurrentNavigation().extras.state.restaurant;
    } else {
      this.back();
    }

    this.responsiveOptions = [
      {
        breakpoint: '2048px',
        numVisible: 3.2,
        numScroll: 3
      },
      {
        breakpoint: '768px',
        numVisible: 2.2,
        numScroll: 2
      },
      {
        breakpoint: '560px',
        numVisible: 1.2,
        numScroll: 1
      }
    ];
  }

  ngOnInit(): void {
    if (this.establishment) {
      // this.establishment.mealCategories.forEach(mealCategory => {
      //   mealCategory.meals.forEach(meal => this.plats.push(meal));
      //   this.visibleMeals = this.plats;
      //   if (mealCategory.meals.length > 0) {
      //     this.filters.push(mealCategory);
      //   }
      // });
    }

    this.formules = [
      { label: 'Menu MIDI', period: 'Du lundi au vendredi de 12h00 à 15h00', description: 'Entrée + Plat + Dessert', price: 12 },
      { label: 'Menu MIDI', period: 'Du lundi au vendredi de 12h00 à 15h00', description: 'Entrée + Plat ou Plat + Dessert', price: 9 },
      { label: 'Menu MIDI', period: 'Tous les soirs de 19h00 à 23h00', description: '2 menus au choix parmi une sélection de menus phares BURGERS KINGS, et 9 king Nuggets en bonnus !', price: 19.9 },
    ];
  }

  filterMeals(mealCategory: MealCategory) {
    // this.visibleMeals = this.plats.filter((meal: Meal) => meal.mealCategory === "/api/meal_categories/" + mealCategory.id);
  }

  showCarte() {
    this.displayCarte = true;
    this.displayFormules = false;
  }

  showFormules() {
    this.displayFormules = true;
    this.displayCarte = false;
  }

  back() {
    this.location.back();
  }

}
